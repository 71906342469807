import HdxxCard from './HdxxCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import HdxxListUtil,{IHdxxListDataObj} from './hdxxListUtil';
import language from './hdxxLanguage'
export default defineComponent ({
    name: 'HdxxList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IHdxxListDataObj=reactive<IHdxxListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: HdxxCard,
                cardFrom:'HdxxList',
                modelMethod: utils.OrderProviderApi.buildUrl('/hdxx/pageData')
            },
            otherParams:{

            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new HdxxListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'未发运'},{value:1,label:'已维护货代信息'},{value:2,label:'已启票'},{value:3,label:'已发运通知'},{value:4,label:'已上传报关单'}]
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.selectRow['F_ID'],
                    fileSize:80,
                    accept:'.pdf',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        const formatPageInfo=(options:any)=>{
            return options;
        }
        //清关
        const qgHandler=()=>{
            // dataObj.otherParams.visible=true;
            // nextTick(()=>{
            //     dataObj.refMap.get('qgdj').uploadParams.belongMaxId=dataObj.otherParams.selectRow['F_ID'];
            // })
        }
        //确认清关
        const sureQgHandler=async ()=>{
            if(dataObj.refMap.get('qgdj').fileList.length==0) {
                proxy.$message({type:'error',message:'至少上传一份清关单据'});
                return false;
            }else{
                await dataObj.utilInst.doQg();
            }
        }
        return{
            ...toRefs(dataObj),comboSelect,buildUploadInitParams,handlePreview,formatPageInfo
            ,qgHandler,sureQgHandler
        }
    }
});