import BaseBean from "@/utils/BaseBean";
import QpCard from "@/views/project/order/fy/qp/QpCard.vue";
import BgzlCard from "@/views/project/order/fy/bgzl/BgzlCard.vue";
export interface IHdxxListDataObj {
    utilInst:HdxxListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class HdxxListUtil extends BaseBean{
    public dataObj:IHdxxListDataObj

    constructor(proxy:any,dataObj:IHdxxListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.otherParams.networkData=await this.utils.Api.buildNetworkData();
    }
    //清关
    public async doQg():Promise<any>{
        let res=await this.utils.OrderProviderApi.qgAction({params:{id:this.dataObj.otherParams.selectRow['F_ID'],type:this.dataObj.otherParams.selectRow['F_TYPE']}});
        if(res.result){
            this.utils.Tools.success();
            this.proxy.pageListRef.queryHandler();
            this.dataObj.otherParams.visible=false;
        }
    }
}